import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Layout, SEO } from "../components/structure";
import {
  Hero,
  Profile,
  Button,
  Content,
  CallToAction,
  StatisticBlock,
  IconTile,
  BlueBlock,
  GrayBlock,
} from "../components/blocks";

import styled from "styled-components";
import { mq } from "../styles";
import CountUp from "react-countup";

import english from "../../content/translations/en-CA/about.json";
import french from "../../content/translations/fr-CA/about.json";

const StatsCardWrapper = styled.div`
  display: block;

  @media screen and ${mq.minSm} {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const IndexPage = () => {
  let lang = english;
  const data = useStaticQuery(graphql`
    query {
      heroImg: file(base: { eq: "GettyImages-sb10068010a-003.jpg" }) {
        ...HeroImage
      }
      lt_rob: file(base: { eq: "rob-maclean.png" }) {
        ...TeamProfileFragment
      }
      lt_chris: file(base: { eq: "christopher-barnard.png" }) {
        ...TeamProfileFragment
      }
      lt_danielle: file(base: { eq: "danielle-brown.png" }) {
        ...TeamProfileFragment
      }
      lt_erick: file(base: { eq: "erick-georgiou.png" }) {
        ...TeamProfileFragment
      }
      lt_inez: file(base: { eq: "inez-murdoch.png" }) {
        ...TeamProfileFragment
      }
      lt_chrisBoyd: file(base: { eq: "chris-boyd.png" }) {
        ...TeamProfileFragment
      }
      lt_jayMalowney: file(base: { eq: "jay-malowney.png" }) {
        ...TeamProfileFragment
      }
      lt_donDew: file(base: { eq: "don-dew.png" }) {
        ...TeamProfileFragment
      }
      icon14: file(base: { eq: "icon-14.png" }) {
        ...IconTileImage
      }
      icon17: file(base: { eq: "icon-17.png" }) {
        ...IconTileImage
      }
      icon18: file(base: { eq: "icon-18.png" }) {
        ...IconTileImage
      }
    }
  `);

  return (
    <Layout>
      <SEO title={lang.meta.title} description={lang.meta.description} />
      <Hero
        title={lang.hero.title}
        subtitle={lang.hero.subtitle}
        image={data["heroImg"]}
      />
      <Content>
        <div style={{ width: "90%", maxWidth: "1080px", margin: "75px auto" }}>
          <h2 className={"kl"}>{lang.content.intro_one.title}</h2>
          <p
            style={{ maxWidth: "1050px", margin: "0px 15px", fontSize: 19 }}
            dangerouslySetInnerHTML={{ __html: lang.content.intro_one.copy }}
          ></p>
        </div>
      </Content>
      <BlueBlock style={{ marginTop: "40px", maxWidth: "1050px" }}>
        <h2 className={"kl"}>{lang.content.intro_two.title}</h2>
        <StatsCardWrapper>
          <StatsCard stat={500} decimal={0} prefix={""} suffix={"+"}>
            <p style={{ fontSize: 20 }}>
              {lang.content.intro_two.blocks.block_one.copy}
            </p>
          </StatsCard>
          <StatsCard stat={8} decimal={0} prefix={""} suffix={""}>
            <p style={{ fontSize: 20 }}>
              {lang.content.intro_two.blocks.block_two.copy}
            </p>
          </StatsCard>

          <StatsCard stat={200} dcimal={0} prefix={""} suffix={"+"}>
            <p style={{ fontSize: 20 }}>
              {lang.content.intro_two.blocks.block_three.copy}
            </p>
          </StatsCard>
        </StatsCardWrapper>
      </BlueBlock>
      <Content
        style={{ margin: "75px auto", textAlign: "center", maxWidth: "850px" }}
      >
        <h2 className={"kl"} style={{ fontSize: 40, padding: "0px 20px" }}>
          {lang.call_to_action.title}
        </h2>
        <Button
          destination={lang.call_to_action.button_plusgrade_link}
          label={lang.call_to_action.button_plusgrade}
        />
      </Content>
      <GrayBlock style={{ padding: "10px 0 15px" }}>
        <Content style={{ background: "transparent" }} whiteOnGray>
          <CallToActionCardContainer>
            <IconTile
              title={lang.content.intro_below_cta.careers.title}
              icon={data["icon14"]}
            >
              <p>{lang.content.intro_below_cta.careers.copy}</p>
              <Button
                destination={lang.content.intro_below_cta.careers.button_link}
                label={lang.content.intro_below_cta.careers.button_text}
              />
            </IconTile>
            <IconTile
              title={lang.content.intro_below_cta.get_in_touch.title}
              icon={data["icon17"]}
            >
              <p>{lang.content.intro_below_cta.get_in_touch.copy}</p>
              <Button
                destination={
                  lang.content.intro_below_cta.get_in_touch.button_link
                }
                label={lang.content.intro_below_cta.get_in_touch.button_text}
              />
            </IconTile>
            {/* <IconTile title={lang.content.intro_below_cta.resource.title} icon={data['icon18']}>
          <p>{lang.content.intro_below_cta.resource.copy}</p>
          <Button destination={lang.content.intro_below_cta.resource.button_link} label={lang.content.intro_below_cta.resource.button_text} />
        </IconTile> */}
          </CallToActionCardContainer>
        </Content>
      </GrayBlock>
    </Layout>
  );
};

const CallToActionCardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  justify-content: space-evenly;

  @media screen and ${mq.minSm} {
    padding-top: 45px;
    flex-direction: row;
  }

  > div {
    transform: none !important;
    margin-bottom: 2rem;

    @media screen and ${mq.minSm} {
      width: 32%;
      margin-bottom: 0;
    }
  }
`;

export default IndexPage;

const StatsCardElement = styled.div`
    text-align: center;
    padding: 30px 20px;
    position: relative;
    flex-basis: 100%;
    margin-bottom: 15px;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    align-items: center;
    
    > div {
      width: 100%;
    }

    @media screen and ${mq.minMd} {
      flex-basis: 24%;
      margin-bottom: 0;
      display: inline-block;
    }
    @media screen and ${mq.minSm} {
      flex-basis: 23%;
      
    }

    span {
      font-size: 50px;
      line-height: 1;
      font-weight: 900;
      left: 0;
      right: 0;
      top: 0;
      color: #fff;
      margin-bottom: 20px;
      display: block;
    }
    }
  `;

const StatsCard = ({ stat, children, decimal, prefix, suffix, ...props }) => (
  <StatsCardElement {...props}>
    <span>
      <CountUp
        end={stat}
        enableScrollSpy={true}
        scrollSpyOnce={true}
        duration={3}
        decimals={decimal}
        prefix={prefix}
        suffix={suffix}
        useEasing={true}
        preserveValue={true}
      />
    </span>
    <div>{children}</div>
  </StatsCardElement>
);
